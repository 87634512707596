.resources-images{border-radius: 20px;margin-top: 80px;}
.resources-images img{border-radius: 25px;}
/*responsive*/
@media only screen and (max-width:767px){
    .resources-images{text-align: center;}
}
@media only screen and (max-width:479px){
    .resources-images {padding-top: 30px;width: 75%;margin: 0 auto;}
}


