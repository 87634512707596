.how-to-create{font-size: 18px;color: #ffffff;font-weight: 400; font-family: "Poppins regular";text-align: center;opacity: 0.5;}
.how-it-works .setup-card img{width: 50px;}
.how-it-works .setup-card h2{font-size: 23px;line-height: 74px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold";margin: 0;}
.how-it-works .setup-card p{font-size: 17px;line-height: 27px;color: #837298;font-weight: 400;font-family: "Poppins regular";text-align: center;margin: 0;}
.how-it-works .setup-card{text-align: center;}
.how-it-works{ margin-bottom: 100px;padding-bottom: 0;}
section.how-it-works{padding: unset;}
    @media only screen and (max-width:1399px){
        .how-it-works .setup-card h2 {font-size: 19px !important;}
    }
    @media only screen and (max-width:1199px){
        .how-it-works .setup-card h2 {font-size: 17px !important;}  
        .how-it-works .setup-card {margin: 0 0 25px;}  
    }
    @media only screen and (max-width:991px){
        .how-it-works .setup-card h2 {font-size: 15px !important;}
        .how-it-works .setup-card p {font-size: 13px;line-height: 18px;}
        .how-it-works .tab-area .nav-link {font-size: 11px;padding: 11px 4px;}
    }
    @media only screen and (max-width:575px){
        .how-it-works {padding-top: 45px;margin-bottom: 24px;}
    }
   