.custom-card .card{  border: none; background-color: transparent;border-radius: 20px; overflow: hidden;margin-bottom: 10px;padding: 0;}
.custom-card{position: relative;left: 50%;transform: translate(-50%); border-radius: 20px; position: relative; margin-right: 70px;}
.custom-card::before {content: "";position: absolute;right: -54px;left: 54px;top: -54px;bottom: 54px;background-color: transparent;border-radius: 20px;border: 2px solid rgba(255, 255, 255, 0.2);}
.custom-card .card .card-body{background-color: #281b3f;color: #fff;}
.custom-card .card-content h2{margin-bottom: 10px; font-size: 42px;color: #ffffff;font-weight: 700;font-family: "Poppins bold";display: inline;}
.custom-card .card-content .custom-card-link{display: flex;align-items: center; font-size: 19px;color:#948d9f;font-weight: 400;font-family: "Poppins regular";    line-height: 22px;}
    .custom-card .card-content .custom-card-link a{ text-decoration: underline 2px;text-underline-offset: 10px;font-size: 19px;color:#948d9f;font-weight: 400;font-family: "Poppins regular";}
.custom-card .card-content span{font-size: 42px;letter-spacing: 4px;color: #ffffff;font-weight: 600;font-family: "poppins semi-bold";padding-right: 5px;}
.custom-card .card-content img{width: 25px;opacity: 0.4;}
.custom-card .card-content{padding: 0px 20px;}
.custom-card .card .card-img-top{border-radius: 0px;height: 100%;object-fit: cover;transition: all .3s ease-in-out;}
.custom-card .card .card-img-top:hover{scale: 1.3;}
.custom-card .card-body {flex: 1 1 auto;padding: 1rem;z-index: 999;}
.custom-card span.icon-circle-heart{width:43px;height:43px;border-radius:50px;background:#3e3253;font-size:23px;color:#281b3f;letter-spacing: 0;padding: 0;}
@media only screen and (max-width:1599px){
    .custom-card { margin-right: 0px;width: 572px;}
}
@media only screen and (max-width:1399px){
    .custom-card .card-content h2 {font-size: 30px;}
    .custom-card .card-content span {font-size: 30px;}
    .supported-blocks a {font-size: 18px;}
    .banner-content h1{font-size: 80px;}
    .custom-card {margin-right: 0px;width: 536px;}
}
@media only screen and (max-width:1199px){
    .custom-card .card-content .custom-card-link,.custom-card .card-content .custom-card-link a{font-size: 12px;}
    .custom-card {margin-right: 0px;width: 437px;}
    .custom-card .card-content h2 {font-size: 24px;}
    .custom-card .card-content img {width: 20px;}
    .custom-card .card-content span {font-size: 16px;}
}
@media only screen and (max-width:575px){
    .custom-card span.icon-circle-heart{width: 30px;height: 30px;}
    .custom-card::before {right: -10px;left: 28px;}
    .custom-card .card-content h2 {font-size: 16px;}
    .custom-card .card-content span {font-size: 12px;}
    .custom-card .card-content a{font-size: 14px;}
    .custom-card .card-content {padding: 0px;}
   
}
@media only screen and (max-width:479px){
    .custom-card::before{display: none;}
}


