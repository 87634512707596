.about-nfts{ margin: 0 auto;}
.about-nfts .view-section {margin: 0 0 25px;}
.about-nfts .top-collection{margin: 0 0 100px;}
.about-nfts .items{display: flex;align-items: center;justify-content: start;flex-wrap: wrap;}
.about-nfts .items .item{width: calc(25% - 20px);margin: 0 20px 20px 0;}
.about-nfts .items .item .card{background-color: #22192b;border-radius: 20px;padding: 10px;position: relative;overflow: hidden;max-height: 480px;min-height: 480px;}
.about-nfts .items .item .card .image-over {height: 300px;}
.about-nfts .items .item .card .image-over img{height: 100%;border-radius: 20px;transition:  all .3s ease-in-out;}
.about-nfts .items .item .card .card-body .nft-name{padding: 0 10px;}
.about-nfts .items .item .card .card-body .nft-name h5{font-family: 'poppins medium';font-weight: 500;font-size: 12px;height: auto; line-height: 17px;}
.about-nfts .items .item .card .card-body .nft-name .number{font-family: 'poppins medium';font-weight: 500;font-size: 13px;color: #fff; height: auto;}
.about-nfts .items .item .card .card-body .nft-name a{font-size: 12px;color: #755697;cursor: pointer;}
.about-nfts .items .item .card .card-body {padding: 20px 0px 0;}
.about-nfts .items .item .card .card-body .biding-section{background-color: #281d33;width: 100%;border-radius: 10px;padding: 15px;}
.about-nfts .items .item .card .card-body .biding-section .biding-data .purple-span{color: #755697;font-size: 12px;font-family: 'poppins semi-bold';font-weight: 700;}
.about-nfts .items .item .card .card-body .biding-section .biding-data  .price {font-size: 12px;font-family: 'poppins regular';font-weight: 400;}
.about-nfts .items .item .card .card-body .biding-section .eth-img {width: 25px;height: 25px;}
.about-nfts .items .item .card .card-body .biding-section .eth-img img{width: 100%;height: 100%;object-fit: contain;}
.about-nfts .items .item .card .card-body .buy-section {padding: 11px 10px;position: absolute;bottom: 0;left: 0;right: 0;background-color: #5c2da1;}
.about-nfts .items .item .card .card-body .buy-section .buy-btn {position: static;bottom: 0px;left: 0px;font-size: 12px;background-color: transparent;color: #fff;border: none;padding: 0;}
.about-nfts .items .item .card .card-body .likes-section .heart-icon{font-size: 12px;color: #755697;cursor: pointer;}
.about-nfts .items .item .card .card-body .likes-section .heart-icon.active{color: red;}
.about-nfts .items .item .card .card-body .likes-section .likes-count{color: #755697;font-size: 12px;font-family: 'poppins semi-bold';font-weight: 700;}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){
    .about-nfts .items .item{width: calc(33.33% - 20px);margin: 0 20px 20px 0;}
}
@media only screen and (max-width:1399px){
    .about-nfts .items .item{width: calc(33.33% - 20px);margin: 0 20px 20px 0;}
}
@media only screen and (max-width:1199px){
    .about-nfts .items {flex-wrap: wrap;}
    .about-nfts .items .item{width: calc(50% - 20px);margin: 0 20px 20px 0;}
}
@media only screen and (max-width:991px){
    .about-nfts .items .item{width: calc(50% - 20px);margin: 0 20px 20px 0;}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){
    .about-nfts .items .item{width: 100%;margin: 0 0 20px 0;}
}
@media only screen and (max-width:479px){
    .about-nfts .items .item { width: 100%;}
}
@media only screen and (max-width:319px){

}