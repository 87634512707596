.search-filters .toggle-button{width: 36px;}
.search-filters .filters-section{width: calc(100% - 56px);margin-left: 20px;}
.search-filters .filters-section .filters-one{width: 50%;}
.search-filters .filters-section .filters-two{width: 40%;}
.search-filters .reset-btn{background-color: transparent !important;color: rgb(119, 105, 143) !important ; margin-left: 20px;}
.search-filters .filters-two .css-1s2u09g-control{ width: 200px;margin-left: 30px;}
.search-filters .filters-two .css-1pahdxg-control{width: 200px;margin-left: 30px;}
.search-filters .filters-two .search-section{position: relative;width: calc(100% - 230px);}
.search-filters .filters-two input{width: 100%;background-color: transparent !important;border-color: rgba(119, 105, 143, 0.5) !important;border-radius: 40px !important;color: rgb(119, 105, 143);height: 55px;padding: 0 0px 0 60px;}
.search-filters .filters-two input::placeholder{color: rgb(119, 105, 143) !important;}
.search-filters .filters-two .search-icon {position: absolute;left: 30px;top: 50%;transform: translateY(-50%);}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){
    .search-filters .filters-section .filters-one {flex-wrap: wrap;}
}
@media only screen and (max-width:1599px){
    .search-filters .filters-two .search-section {width: 100%;}
    .search-filters .filters-section .filters-two {flex-direction: column;}
    .search-filters .filters-section .filters-one {justify-content: start !important;}
}
@media only screen and (max-width:1399px){

}
@media only screen and (max-width:1199px){
    .search-filters .filters-section {flex-direction: column;}
    .search-filters .filters-section .filters-one {width: 100%;}
    .search-filters .filters-section .filters-two {width: 60%;}
}
@media only screen and (max-width:991px){
    .search-filters .search-filters-in {flex-direction: column;align-items: flex-start !important;}
    .search-filters .toggle-button {display: block;margin: 0 0 25px;}
    .search-filters .filters-section {width: 100%;margin-left: 0px;}
}
@media only screen and (max-width:767px){
    
    .search-filters .filters-section .filters-two {width: 100%;}
}
@media only screen and (max-width:575px){
    .search-filters .reset-btn{margin-left: 0px;}

}
@media only screen and (max-width:479px){

}
@media only screen and (max-width:319px){

}