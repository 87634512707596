.ui.segment.tweet:hover {
  border: 2px solid #1da1f2;
  color: black;
}

.ui.segment.tweet p {
  color: black;
}


.twitter-image-wrapper{height: 130px;position: relative; margin-bottom: 50px;}
.twitter-image-wrapper img{width: 100px;position: absolute;margin: 0px auto; top: 50%;left: 30%;transform: translate(-50%,-50%);  animation: rotate 5s infinite;}
.tweets{
  position: relative;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.end-vales{font-size: 12px;}
.twitter-feedback{height: 2px;}
@-moz-keyframes rotate {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes rotate {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes rotate {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}