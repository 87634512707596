.Statistics{padding: 0;}
.Statistics-content ul{list-style: none;}
.Statistics h2{margin-bottom: 0px; font-size: 33px;line-height: 78px;color: #827297;font-weight: 600;font-family: "Poppins semi-bold";}
.Statistics-content .values h4{margin-bottom: 16px; font-size: 49px;letter-spacing: -1px;color: #ffffff;font-weight: 600;font-family: "Poppins bold";}
.Statistics-content{text-align: center; margin-bottom: 90px;}
.Statistics-content .values p{opacity: 0.3;  display: inline; font-size: 20px;letter-spacing: 0px; color: #ffffff;font-weight: 400;font-family: "Poppins regular";}

/*responsive*/
 @media only screen and (max-width:1599px){
    .Statistics-content .values h4{font-size: 40;}
    .Statistics-content .values p{font-size: 17px;}
}
@media only screen and (max-width:1399px){
    .Statistics-content .values h4{font-size: 35px;}
}
@media only screen and (max-width:1199px){
    .Statistics-content .values h4{font-size: 30px;}
    .Statistics-content .values p{font-size: 15px;}
}

@media only screen and (max-width:768px){
    .Statistics-content .values h4{font-size: 26px;}
    .Statistics-content .values p{font-size: 14px;}
}
@media only screen and (max-width:575px){
    .Statistics-content .values h4{font-size: 20px;margin-bottom: 10px;}
    .Statistics-content .values p{font-size: 12px;}
}


