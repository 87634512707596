.popular-card{position: relative;margin: 0px 20px; height: 310px;max-height: 310px;}
.popular-overlay{position: absolute;bottom: 5%;left: 5%;}
.popular-overlay h3{font-size: 30px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold";}
.popular-card img{height: 100%;border-radius: 25px;width: 100%;object-fit: fill;}
@media only screen and (max-width:1599px){
.popular-card{height: 270px;}
}
@media only screen and (max-width:1399px){
    .popular-card{height: 250px;}
    .popular-overlay h3{font-size: 17px;}
    .popular-overlay{bottom: 2%;}
}
@media only screen and (max-width:1199px){
    .popular-card{height: 265px;}
}


