.trending{padding-top: 70px;margin-bottom: 100px;padding-bottom: 0;}
.custom-heading{margin-bottom: 70px;}
.trending .trending-nft-card{position: relative; overflow: hidden; max-height: 535px; min-height: 535px; padding:10px;background-color: #22192b;margin: 0px 20px;border-radius: 20px;color: #fff;}
.trending .trending-nft-card .card-body{padding: 5px 17px;overflow: hidden;}
.trending-nft-card .trending-image{min-height: 370px; border-radius: 20px; position: absolute;}
.trending-nft-card .card-img-top.trending-images{height: 370px;width: 100%;}
.trending .trending-nft-card img{object-fit: cover;transition: 0.3s;}
.trending .trending-nft-card img:hover{scale: 1.1;padding:10px;}
.trending .card .mettoz a{font-size: 11px;line-height: 22px;text-decoration: underline 2px;color: #815ea7;font-weight: 300;font-family: "Poppins regular"; }
.tik-icon img {
    width: 22px;
    height: 22px;
    margin-top: 5px    
}
.trending-value{background-color: #322342;border-radius: 5px;padding: 5px;}
.value-area{margin-bottom: 15px;}
.price-area{background-color: #281d33;padding: 15px 20px; border-radius: 15px;position: absolute;width: 90%;bottom: 2%;left: 50%;transform: translateX(-50%);}
.price-area img{opacity: 0.5;width: 10px;}
.price-area .price,.price-area .highest-bid{font-size: 11px;line-height: 19px;color: #815ea7;font-weight: 300;font-family: "Poppins regular";}
.eth{font-size: 11px;color: #ffffff;font-weight: 300;font-family: "Poppins regular";}
.price-content{display: flex;align-items: center;justify-content: space-between}
.eth,.highest-bid{margin-right: 11px;}
.arow-icon img{margin-top: -20px;}
@media only screen and (max-width:1599px){ 
    .trending-value{padding: 0px;}
    .trending .trending-nft-card .card-body {padding: 15px 5px;}
}
@media only screen and (max-width:1199px){ 
    .trending .trending-nft-card .card-body {padding: 15px 0px;}
}
@media only screen and (max-width:991px){
    .value-area {margin-bottom: 10px;font-size: 12px;}
    .price-area{padding: 20px 20px;}
}
@media only screen and (max-width:768px){
    .trending {padding-top: 40px;margin-bottom: 42px;}
}
@media only screen and (max-width:767px){
    .custom-heading { margin-bottom: 40px !important;text-align: center;}

}
@media only screen and (max-width:575px){
    .trending .trending-nft-card {margin: 0px;}
}
