.banner{margin-bottom: 100px;padding-bottom: 0;}
.banner .banner-content span{font-size: 55px;letter-spacing: -2px;color: #ffffff;font-weight: 400;font-family: 'poppins regular';}
.banner .banner-content h1{padding-top: 20px; margin-bottom: 40px; font-size: 107px;letter-spacing: -2px;color: #ffffff;font-weight: 400;font-family: 'poppins bold';}
.banner .banner-content p{width: 600px; position: relative; margin-bottom: 60px; font-size: 26px;letter-spacing: 2px;line-height: 30px;color: #bfbfbf;font-weight: 400;font-family: 'poppins regular';margin-top: 0;}
.banner .form-control{padding-right: 60px; position: relative; height:41px;margin-bottom: 70px; border:2px solid #827d89;background-color: transparent;border-radius: 10px;font-size: 12px;letter-spacing: 2px;font-family: "Poppins regular";transition: all ease-in-out 0.3s;}
.banner .form-control:focus{box-shadow: none;background-color: transparent;border-color:#fff;}
.banner .form-control::-webkit-input-placeholder {color:rgba(255,255,255,0.20);}
.banner .form-control::-moz-placeholder {color:rgba(255,255,255,0.20);opacity:  1;}
.banner .form-control::-moz-placeholder {color:rgba(255,255,255,0.20);opacity:  1;}
.banner .form-control:-ms-input-placeholder {color:rgba(255,255,255,0.20);}
.banner .form-control::-ms-input-placeholder {color:rgba(255,255,255,0.20);}
.banner .form-control::placeholder {color:rgba(255,255,255,0.20);}
.banner .custom-border-bottom:before{
    position: absolute;
    background-image: linear-gradient(to right, #fd5492, #f86f64);
    width: 63px;
    height: 15px;
    content: "";
    bottom: -7%;
    left: 18.5%;
     z-index: -1;
}
.custom-dropdown-menu-2 .banner-search-close-icon{cursor: pointer; position: absolute;right: 5%;top: 2%; z-index: 9999; font-size: 18px;color: rgb(255, 255, 255);font-weight: bold;}
.custom-dropdown-menu-2 .banner-search-close-icon:hover{color: red;}
.custum-header-input{position: relative;width: 520px;}
.custom-drop-down-toggle{position: absolute;display: inline-block;background-color: transparent;right: 30px;z-index: 1000;color: #fff;top: 10px;}
.custum-header-input .dropdown{width: 100%;}
.custum-header-input .dropdown-menu.show{ width: 100%; transform: none !important; margin-top: -70px;background-color: #1a1226;}
.custum-header-input .dropdown-menu p{margin-bottom: 5px !important;padding: 20px;font-size: 20px;}
.custom-dropdown-menu-2 a{font-size: 14px !important;}
.custum-header-input  .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0;
  vertical-align: 0;
  content: "";
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.banner .explore-now,.banner .how-works{ margin-bottom: 65px;padding:0 0 5px; position: relative; font-size: 21px;line-height: 24px;color: #746f7b !important;font-weight: 500 !important;font-family: 'poppins semi-bold';}
.banner-arrow{padding-left: 10px;}
.banner .how-works{margin-left: 70px;}
.banner-arrow-hover{display: none;padding-left: 10px;}
.banner .explore-now:before,.banner .how-works:before{width: 45px;height:3px;position: absolute; background: #746f7b; content: "";left: -1px;bottom: -10px;transition: all .3s ease-in-out;}
.banner .explore-now:hover,.banner .how-works:hover {color: #fb517a !important;}
.banner .explore-now:hover:before,.banner .how-works:hover:before{background:#fb517a; width: 120px;}
.banner .explore-now:hover .banner-arrow{display: none;}
.banner .explore-now:hover .banner-arrow-hover{display: inline-block;}

.explore a:hover{color: #fb517a ;}
.banner .supported-blocks p{margin-bottom: 20px; font-size: 33px;color: #827297;font-weight: 600;font-family: 'poppins semi-bold';}
.banner .supported-blocks .custom-blockchain-link{cursor: pointer; font-size: 23px;color: #827297;margin:0 26px 0 0;justify-content: flex-start;transition: all ease-in-out 0.3s;}
.banner .supported-blocks .custom-blockchain-link:hover{color: #ffffff;}
.banner:before{position: absolute;content: "";width: 100%;height: 270px;background-image: url("./../../../assets/images/side-image.png");background-repeat: no-repeat;}
.banner .collectible{padding-left: 42px; font-size: 23px;line-height: 44px;color: #ffffff;font-weight: 400;font-family: "Poppins regular";opacity: 0.3;}
.banner .gradient-image img{position: absolute;right: -2%;top: 16%;}
.banner .banner-card-area {position: static;}
.no-search-found{font-size: 18px;font-weight: 500;font-family: 'poppins Regular';}
.custum-header-input .dropdown-menu.show{position: absolute;}

/* responsive */
@media only screen and (max-width:1919px){
    .banner .banner-content h1{font-size: 90px;}
    .banner .banner-content p{font-size: 24px;}
    .banner .form-control {width: 515px;}
}
@media only screen and (max-width:1799px){
    .banner .custom-border-bottom:before{left: 17%;}
    .banner .gradient-image img {right: 15%;top: 14%;width: 800px;}
}
@media only screen and (max-width:1599px){
    .banner .gradient-image img {right: 8%; top: 15%;width: 700px;}
    .banner{margin-bottom: 56px;}
    .banner .supported-blocks .custom-blockchain-link{font-size: 16px;}
}
@media only screen and (max-width:1399px){
    .banner .banner-content{padding-left: 30px;}
    .banner .gradient-image img {right: 15%;top:15%; width: 550px;}
    .banner .supported-blocks a {font-size: 18px;}
    .banner .banner-content h1{font-size: 75px;}
    .banner .banner-content span {font-size: 48px;}
    .banner .custom-card {margin-right: 0px;width: 450px;}
    .banner .collectible {padding-left: 94px;line-height: 15px;}
}
@media only screen and (max-width:1199px){
    .custum-header-input .dropdown{width: 462px;}
    .custum-header-input button{right: 15%;}
    .banner .form-control{width: 460px;}
    .custum-header-input .dropdown-menu.show{width: 100%;}
    .banner .supported-blocks .custom-blockchain-link {font-size: 12px;}
    .banner .gradient-image img {right: 14%; width: 466px;top: 17%;}
    .banner .banner-content h1 {font-size: 60px;}
    .banner .banner-content span {font-size: 30px;}
    .banner .banner-content p {font-size: 21px;width: 418px;}
    .banner .supported-blocks a {font-size: 13px !important;}
    .banner .custom-container{padding-left: 50px !important;padding-right: 50px !important;}
    .banner .custom-card {margin-right: 0px;width: 380px;}
    .banner .custom-border-bottom:before{left: 35%;}
}
@media only screen and (max-width:991px){
    .custum-header-input button{right: 3%;}
    .custum-header-input .dropdown{width: 100%;}
    .custom-drop-down-toggle{right: 30px;}
    .custum-header-input{width: 100%;}
    .banner .custom-border-bottom:before{left: 83%;top: 20%;}
    .banner .gradient-image{display: none;}
    .banner .banner-content{text-align: center;}
    .banner .banner-content p{width: 100%;}
    .banner .form-control{width: 100%;}
    .banner .banner-content{margin-bottom: 40px;}
    .banner .custom-card {width: 520px;}
    .banner .resources-images img {width: 100%;}
}
@media only screen and (max-width:767px){
    .banner .custom-card { width: 411px;}
    .banner .Statistics-content .category li {padding: 0px 17px;}
    .banner .custom-border-bottom:before{display: none;}
    /* .banner {padding-top: 120px;} */
}
@media only screen and (max-width:575px){
    .banner .banner-content{padding: unset;}
    .banner-arrow{display: none;}
    .banner .collectible{padding: unset;font-size: 18px;}
    .banner .custom-border-bottom:before{display: none;}
    .banner:before {width: 15px; height: 260px;   }
    .banner .explore-now, .banner .how-works{font-size: 16px;}
    .Statistics-content .category li {padding: 0px 21px;font-size: 9px;}
    .resources-images{text-align: center;}
    .banner .banner-content p{font-size: 18px;}
    .banner .how-works {padding-left: 50px;font-size: 12px;}
    .banner .supported-blocks a {font-size: 12px !important;}
    .custom-card{width: 100%;}
    .collectible{font-size: 16px;}
    .footer button {height: 25px !important;padding: 0px 17px !important;font-size: 8px !important;}
    .footer .form-control{min-height: 50px !important;}
    .banner .how-works {padding-left: 10px;}
}
@media only screen and (max-width:479px){
    .banner .banner-content h1 {font-size: 39px;}
    .banner .banner-content span{font-size: 23px;}
    .banner .banner .how-works{padding-left: 20px;}
    .banner .supported-blocks a {padding: 0px 8px;}
    .blockchain-patforms, .explore {flex-wrap: wrap;}
    .blockchain-patforms{justify-content: center;}
    .custum-header-input button{margin-right: 3%;font-size: 14px;}
    .banner .supported-blocks a {padding: 0;margin: 10px 12px;}
    .banner .custom-card {width: 100%;}
    .banner .banner-card-area { padding-top: 0 !important;}
    
}
@media only screen and (max-width:280px){
    .banner .banner-content p {
        font-size: 13px;
        line-height: 16px;
    }
    .banner .explore-now, .banner .how-works {
        font-size: 12px;
    }
}
