.Statistics .collection-card{position: relative;margin: 0px 20px;min-height: 300px;max-height: 378px;}
.collection-card .card-img{height: 378px;max-height: 378px; border-radius: 25px;max-height: 380px;width: 100%;object-fit: cover;}
.fade-overlay{position: absolute;bottom: 0;left: 0%;width: 100%;height: 100%;border-radius: 25px;background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.01), rgba(255, 251, 251, 0.3));}
.collection-overlay{position: absolute;bottom: 0%;padding: 10px 20px;width: 100%; background: rgb(38 35 35 / 45%);}
.collection-overlay h3{font-size: 20px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold";margin: 0 0 2px 0;}
.collection-overlay span,.collection-overlay a{opacity: 0.6; position: relative; font-size: 13px;color: #ffffff;font-weight: 400;font-family: "Poppins regular";}
.tik-icon{width: 20px; height: auto; margin-left: 2px;}
.collection-overlay a{text-decoration: underline 2px !important;text-underline-offset: 3px;}
.react-multiple-carousel__arrow--left::before {content: "\e824";color: #673f9a !important;font-weight: 600 !important;}
.react-multiple-carousel__arrow--right::before {content: "\e825";color: #673f9a !important;font-weight: 600 !important;}
.react-multi-carousel-dot button {display:block;width: 153px !important;height:6px !important;padding:0 !important;border: none !important;margin: 0px !important;background-color: transparent;border-radius:0 !important}
.react-multi-carousel-dot:hover button,
.react-multi-carousel-dot--active button {background-image: linear-gradient(to right, #fd5492, #f86f64) !important;}
.react-multi-carousel-list{padding:0 0 80px;}
.react-multi-carousel-dot-list {bottom: 24px;right:auto;left:50%;-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);border-bottom:1px solid rgba(178,175,182,0.20);}
.react-multi-carousel-dot-list li{margin: 0;}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){
    .collection-overlay h3{font-size: 15px;}
    .collection-card .card-img,.Statistics .collection-card{height: 300px;}
}
@media only screen and (max-width:1399px){
    .collection-overlay h3{font-size: 15px;margin-bottom: -3px;}
    .tik-icon img{width: 10px;}
    
}
@media only screen and (max-width:575px){.collection-overlay{font-size: 14px;}.fade-overlay{height: unset;}}


 
