.mini-loader-holder {
    background-color: #1a1226;
}
.mini-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.mini-loading {
  /* width: 100%; */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.mini-loading span {
  font-size: 30px;
  font-family: 'poppins semi-bold';
  font-weight: bold;
  text-transform: lowercase;
  letter-spacing: 5px;
  color: #efd9e9;
  position: relative;
  margin: 20px;
}
.mini-loading span::before {
  content: "";
  width: 10%;
  height: 100%;
  background: linear-gradient(90deg,#fd5492,#f86f64);
  /* opacity: 0.5; */
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: color-dodge;
  
  animation: move 2.5s linear infinite;
}
.mini-loading span:last-of-type {
  color: #efd9e9;
  font-family: 'poppins bold';
}
@keyframes move {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 75%;
  }
}
@media (max-width: 575px) {
    .mini-loading span{font-size: 15px;}
}
