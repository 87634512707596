.view-all-collection button {border-radius: 20px;font-size: 16px;background: linear-gradient(to right, #d3487e, #cb5c57);font-weight: 500;font-family: "Poppins medium";padding:1px;border-radius: 20px;overflow:hidden;}
.view-all-collection button span.outer{padding: 22px 104px;background:#1a1226;border-radius: 20px;overflow:hidden;transition: all .3s ease-in-out 0.3s;}
.view-all-collection button span.inner{
  background: -webkit-linear-gradient(left, #d3487e , #cb5c57);
  background: -o-linear-gradient(right, #d3487e, #cb5c57);
  background: -moz-linear-gradient(right, #d3487e, #cb5c57);
  background: linear-gradient(to right, #d3487e , #cb5c57); 
 -webkit-background-clip: text;
 background-clip: text;
 -webkit-text-fill-color: transparent;transition: all ease-in-out 0.3s;}
 /* .view-all-collection button:hover span.outer{opacity:0;}
 .view-all-collection button:hover span.inner{background:none;color:#000;} */
 .view-all-collection {text-align: center;}
 .view-all-collection button span.outer:hover{background-image: linear-gradient(to right, #f56285, #f089e5);color: #fff;}
 .view-all-collection button:hover  span.inner{color: #fff;-webkit-text-fill-color: #fff;}

@media only screen and (max-width:1599px){
  .view-all-collection button span.outer{padding: 17px 75px;}
}
@media only screen and (max-width:1599px){
  .view-all-collection button span.outer{padding: 17px 60px;}
}
@media only screen and (max-width:991px){
  .view-all-collection button span.outer{padding: 17px 47px;} 
}
@media only screen and (max-width:575px){
  .view-all-collection button span.outer{padding: 12px 22px;font-size: 14px;}
}
